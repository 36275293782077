import React from "react"
import { FaFacebook, FaMapPin, FaMailBulk, FaPhone, FaUser, FaUserNinja, FaEnvelope, FaInbox, FaEnvelopeOpenText } from "react-icons/fa"
import Consultanta from "../images/Servicii/Consultanta.svg"
import Achizitie from "../images/Servicii/Achizitie.svg"
import Instalare from "../images/Servicii/Instalare.svg"
import Mentenanta from "../images/Servicii/Mentenanta.svg"
import Reparatie from "../images/Servicii/Reparatie.svg"

export const benefitsTabs = [
  {
    title: "Racire",
    content:
      "Pompa de caldura ofera posibilitatea racirii locuintei pe timpul verii.",
    imagePath: "tab1",
  },
  {
    title: "Emisii scazute",
    content:
      "Contribuie la protejarea mediului prin reducerea emisilor cu pana la 100%.",
    imagePath: "tab2",
  },
  {
    title: "Reducerea costurilor",
    content:
      "Pompa de caldura este cu pana la 50% mai ieftina decat modelele de racire conventionala.",
    imagePath: "tab3",
  },
  {
    title: "Durata de viata ridicata",
    content:
      "Durata medie de viata a unei pompe de caldura este de 15-20 ani.",
    imagePath: "tab4",
  },
]

export const servicesConstants = [
  {
    title: "Consultanta",
    subtitle: "cu privire la proiectul dumneavoastra.",
    image: (
      <img itemProp="image" src={Consultanta} alt="service-svg" className="shadow-effect" />
    ),
  },

  {
    title: "Achizitie",
    subtitle: "prin furnizori autorizati.",
    image: <img itemProp="image" src={Achizitie} alt="service-svg" className="shadow-effect" />,
  },
  {
    title: "Instalare",
    subtitle: "conform standardelor.",
    image: <img itemProp="image" src={Instalare} alt="service-svg" className="shadow-effect" />,
  },
  {
    title: "Mentenanta",
    subtitle: "pentru functionarea optima.",
    image: <img itemProp="image" src={Mentenanta} alt="service-svg" className="shadow-effect" />,
  },
  {
    title: "Reparatie",
    subtitle: "in cazul defectarii echipamentului.",
    image: <img itemProp="image" src={Reparatie} alt="service-svg" className="shadow-effect" />,
  },
]

export const links = [
  {
    path: "/",
    text: "Acasa",
  },
  {
    path: "/",
    text: "Servicii",
  },
  {
    path: "/echipamente",
    text: "Echipamente",
  },
  {
    path: "/proiecte",
    text: "Proiecte",
  },
  {
    path: "/contact",
    text: "Contact",
  },
]

export const socialIcons = [
  {
    icon: <FaFacebook />,
    url: "https://twitter.com",
  },
]

export const contactLeft = [
  {
    icon: <FaMapPin />,
    title: "Unde ne gasiti?",
    details: ["Bihor, Str. 520 nr. 5"]
  },
  {
    icon: <FaMailBulk />,
    title: "Email",
    details: ["info@g4h.ro"]
  },
  {
    icon: <FaPhone />,
    title: "Telefon",
    details: ["+40 746 163 686"]
  }
]

export const contactRight = [
  {
    name: "name",
    icon: <FaUser />
  },
  {
    name: "prenume",
    icon: <FaUserNinja />
  },
  {
    name: "email",
    icon: <FaEnvelope />
  },
  {
    name: "subject",
    icon: <FaInbox />
  },
  {
    name: "textarea",
    icon: <FaEnvelopeOpenText />
  }
]