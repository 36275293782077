import React from "react"

export const FormGroup = ({ icon, name }) => {
  return (
    <div className="contact-form_right-component">
      <label for={name}>{icon}</label>
      {name !== "textarea" ? (
        <input id={name} placeholder={name === "name" ? "nume" : name} name={name} type="text" />
      ) : (
          <textarea placeholder="continut" type="text" />
        )}
    </div>
  )
}
export default FormGroup
