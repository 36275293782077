import React from "react"
import { contactRight } from "../../utilities/constants"
import "./form.styles.scss"
import { FormContent } from "./FormContent"
import { FaMapPin, FaMailBulk, FaPhone } from "react-icons/fa"
import { useIntl } from "gatsby-plugin-intl"
import Footer from "../../components/Footer/Footer"

export const Form = () => {

  const intl = useIntl()

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + 47.059691 + "," + 22.055338);
  };

  return (
    <div className="contact-form_container">
      <div className="footer_display">
        <Footer></Footer>
      </div>
      <div className="contact-form_left">
        <div style={{ cursor: "pointer" }} onClick={() => showInMapClicked()} className="contact-form_left-component" >
          <span className="contact-form_left-icon">{<FaMapPin />}</span>
          <h2>{intl.formatMessage({ id: "contactDetails.0.title" })}</h2>
          <h6>{intl.formatMessage({ id: "contactDetails.0.details.0" })}</h6>
        </div>
        <div className="contact-form_left-component">
          <span className="contact-form_left-icon">{<FaMailBulk />}</span>
          <h2>{intl.formatMessage({ id: "contactDetails.1.title" })}</h2>
          <h6><a style={{ textDecoration: "none", color: "#ffffffc2" }} href="mailto:info@g4h.ro">{intl.formatMessage({ id: "contactDetails.1.details.0" })}</a></h6>
        </div>
        <div className="contact-form_left-component">
          <span className="contact-form_left-icon">{<FaPhone />}</span>
          <h2>{intl.formatMessage({ id: "contactDetails.2.title" })}</h2>
          <h6><a style={{ textDecoration: "none", color: "#ffffffc2" }} href="tel:+40 746 163 686">{intl.formatMessage({ id: "contactDetails.2.details.0" })}</a></h6>
        </div>
      </div>
      <div className="contact-form_right">
        <FormContent inputs={contactRight} />
      </div>
    </div>
  )
}

export default Form
