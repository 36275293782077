import React from 'react'
import Consultanta from "../../images/Servicii/Consultanta.svg"
import Achizitie from "../../images/Servicii/Achizitie.svg"
import Instalare from "../../images/Servicii/Instalare.svg"
import Mentenanta from "../../images/Servicii/Mentenanta.svg"
import Reparatie from "../../images/Servicii/Reparatie.svg"
import { useIntl } from 'gatsby-plugin-intl'

export const Icons = () => {
    const intl = useIntl()

    return (
        <article className="contact-icons">
            <div className="contact-icons_center">
                <div className="contact-icons_component" >
                    <img itemProp="image" src={[Consultanta]} alt="service-icon" />
                    <h6>{intl.formatMessage({ id: "servicesTabs.0.title" })}</h6>
                </div>
                <div className="contact-icons_component" >
                    <img itemProp="image" src={[Achizitie]} alt="service-icon" />
                    <h6>{intl.formatMessage({ id: "servicesTabs.1.title" })}</h6>
                </div>
                <div className="contact-icons_component" >
                    <img itemProp="image" src={[Instalare]} alt="service-icon" />
                    <h6>{intl.formatMessage({ id: "servicesTabs.2.title" })}</h6>
                </div>
                <div className="contact-icons_component" >
                    <img itemProp="image" src={[Mentenanta]} alt="service-icon" />
                    <h6>{intl.formatMessage({ id: "servicesTabs.3.title" })}</h6>
                </div>
                <div className="contact-icons_component" >
                    <img itemProp="image" src={[Reparatie]} alt="service-icon" />
                    <h6>{intl.formatMessage({ id: "servicesTabs.4.title" })}</h6>
                </div>
            </div>
        </article>
    )
}

export default Icons;