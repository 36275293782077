import React from "react"
import { StyledTitle } from "../Home/Title"
import FormGroup from "./FormGroup"
import {
  FaUser,
  FaUserNinja,
  FaEnvelope,
  FaInbox,
  FaEnvelopeOpenText,
} from "react-icons/fa"
import { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

export const FormContent = () => {
  const intl = useIntl()
  const [status, setStatus] = useState("")
  const submitForm = ev => {
    ev.preventDefault()

    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus({ status: "SUCCESS" })
      } else {
        setStatus({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }

  return (
    <div className="contact-form_right_content">
      <StyledTitle>{intl.formatMessage({ id: "contactTitle" })}</StyledTitle>
      <form
        action="https://formspree.io/mayplgoa"
        method="POST"
        onSubmit={submitForm}
      >
        <div className="contact-form_right-labels">
          <span>{intl.formatMessage({ id: "contactFields.0" })}</span>
          <span>{intl.formatMessage({ id: "contactFields.1" })}</span>
          <span>{intl.formatMessage({ id: "contactFields.2" })}</span>
          <span>{intl.formatMessage({ id: "contactFields.3" })}</span>
          <span>{intl.formatMessage({ id: "contactFields.4" })}</span>
        </div>
        <div className="contact-form_right-inputs">
          <FormGroup
            name={intl.formatMessage({ id: "contactFields.0" })}
            icon={<FaUser />} />
          <FormGroup
            name={intl.formatMessage({ id: "contactFields.1" })}
            icon={<FaUserNinja />} />
          <FormGroup
            name={intl.formatMessage({ id: "contactFields.2" })}
            icon={<FaEnvelope />} />
          <FormGroup
            name={intl.formatMessage({ id: "contactFields.3" })}
            icon={<FaInbox />} />
          <FormGroup
            name={intl.formatMessage({ id: "contactFields.4" })}
            icon={<FaEnvelopeOpenText />} />

          <div className="contact-form_right-component">
            <button type="submit" className="main-button shadow-effect">
              {intl.formatMessage({ id: "contactSendButton" })}
            </button>
            <p>
              {status.status === "SUCCESS"
                ? "Emailul a fost trimis !"
                : status === "ERROR"
                  ? "A aparut o eroare , va rugam incercati mai tarziu !"
                  : ""}
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}
