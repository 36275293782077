import React from "react"
import Layout from "../components/Layout/Layout"
import { StyledHeader } from "../components/Header/Header"
import Form from "../components/Contact/Form"
import Icons from "../components/Contact/Icons"
import SEO from "../components/SEO/SEO"
import TagManager from "../components/TagManager/TagManager"
import { graphql } from "gatsby"

export default ({ data }) => {
  return (
    <Layout footerHide>
      <TagManager />
      <SEO
        title="Contact"
        description="Contact. G4H va sta la dispozitie pentru a va indruma in alegerea celei mai bune solutii pentru casa dumneavoastra."
        robots="index, follow"
        canonical="https://g4h.ro/contact"
        openGraph={{ url: "https://www.g4h.ro/contact", type: "website", image: "https://g4h.ro/static/efaf3e54cff1491442342f47dd157102/65e33/img.png", title: "Contact", description: "Contact. G4H va sta la dispozitie pentru a va indruma in alegerea celei mai bune solutii pentru casa dumneavoastra." }}
      />
      <StyledHeader contact image={data.contactheader.childImageSharp.fluid} alt="Contact Header">
        <Form />
      </StyledHeader>
      <Icons />
    </Layout>
  )
}

export const query = graphql`
  {
    contactheader: file(relativePath: { eq: "contact.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
